.subscription-table-container {
  th,
  td {
    padding: 12px !important;
    white-space: nowrap;
  }
  border-radius: 10px;
  border: 1px solid #D1D2D433;
  .action-btn {
    a {
      background: #0a7eca80;
      border: 1px solid #0a7eca;
      padding: 10px 20px;
      border-radius: 38px;
      color: #fff;
      &:hover {
        color: #fff;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .table-tag {
    /* UI Properties */
    background: #0a7eca 0% 0% no-repeat padding-box;
    color: #fff;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin-right: 16px;
    opacity: 1;
  }
  .Mui-active {
    color: #fff !important;
    .MuiTableSortLabel-icon {
      color: #fff !important;
    }
  }
  span.MuiTableSortLabel-root {
    &:hover {
      color: #fff;
      .MuiTableSortLabel-icon {
        opacity: 1;
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1450px) {
  .subscription-table-container {
    th,
    td {
      font-size: 14px !important;
    }
    .table-col-1 {
      min-width: 180px;
    }
    .action-btn {
      min-width: 130px;
    }
  }
}

@media (min-width: 769px) and (max-width: 1400px) {
  .subscription-table-container {
    @for $i from 1 through 2 {
      .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}){
        display: none;
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 768px) {
  .subscription-table-container {
    @for $i from 1 through 4 {
      .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}){
        display: none;
      }
    }
  }
}


@media (min-width: 380px) and (max-width: 539px) {
  .subscription-table-container {
    @for $i from 1 through 5 {
      .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}){
        display: none;
      }
    }
  }
}


@media (min-width: 320px) and (max-width: 379px) {
  .subscription-table-container {
    @for $i from 1 through 5 {
      .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(#{$i}){
        display: none;
      }
    }
  }
}


@media (min-width: 320px) and (max-width: 1023px) {
  .subscription-table-container {
    th,
    td,
    tr {
      font-size: 15px !important;
      line-height: 30px !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .subscription-table-container {
    .table-tag {
      padding: 4px;
    }
  }
}