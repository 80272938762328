.archive {
  height: 100vh;
  display: flex;
  flex-direction: column !important;
  padding: 40px 80px;

  .archive-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px 20px;
    p.page-title {
      font-size: 72px;
      font-weight: bold;
    }
    button.back-btn {
      font-size: 20px;
      font-weight: 500;
      text-transform: capitalize;
      color: #fff;
      background: #9ba4af;
      border-radius: 38px;
      padding: 3px 20px;
      &:hover {
        background: #9ba4af;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 30%);
      }
      svg.back-arrow {
        font-size: 15px;
        font-weight: bold;
        margin-right: 10px;
      }
    }
    button.download-btn {
      background: #feb127;
      border-radius: 38px;
      color: #fff;
      font-size: 20px;
      text-transform: capitalize;
      padding: 5px 20px;
      &:hover {
        background: #feb127;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 30%);
      }
    }
  }
  .archive-content {
    background: #fff;
    padding: 50px 40px 40px;
    border-radius: 20px;
    p.archive-paras {
      overflow-y: scroll;
      height: 500px;
      h3 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
        color: #333;
      }
      p {
        font-size: 24px;
        line-height: 44px;
        color: #666;
      }
      p.para-br {
        margin-top: 40px;
      }
      h5 {
        margin-top: 80px;
        font-size: 24px;
        color: #333;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 10px;
        span {
          font-weight: 400;
          color: #ee7623;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .archive {
    padding: 0px 20px;
    .archive-top-sec {
      text-align: center;
    }
    .archive-head {
      // flex-direction: column;
      margin: 20px 0;
      p.page-title {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        margin: 10px 0;
      }
    }
    .archive-content {
      padding: 20px 30px;
      p.archive-paras {
        h3 {
          font-size: 20px;
          margin-bottom: 0px;
        }
        p {
          font-size: 16px;
          line-height: 25px;
        }
        p.para-br {
          margin: 0px;
        }
        h5 {
          font-size: 20px;
          margin-top: 30px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 550px) {
  .archive {
    .archive-head {
      flex-direction: column;
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .archive {
    .archive-head {
      p.page-title {
        font-size: 40px;
      }
    }
  }
}
