/** @format */

body {
  font-family: "Raleway" !important;
  .modal-head {
    border-bottom: 1px solid #9ba4af;
    margin-bottom: 20px;
    .popup-head {
      h3 {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
        margin-top: 0;
      }
      h5 {
        color: #666666;
        font-size: 16px;
        margin-top: -15px;
        margin-bottom: 1.4em;
        font-weight: 400;
      }
    }
  }
  .popup-main {
    margin-top: 40px;

    p.popup-field-label {
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      width: 100%;
    }
    .payment-select {
      border: 1px solid #9ba4af;
      border-radius: 38px;
      padding: 7px 20px;
      width: 150px;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
      div#demo-simple-select {
        font-size: 16px;
        color: #9ba4af;
        padding: 0;
      }
    }
    button.renew-option {
      border: 1px solid #9ba4af !important;
      border-radius: 38px !important;
      font-size: 16px;
      text-transform: capitalize;
      padding: 5px 20px;
      color: #9ba4af;
      &:first-child {
        margin-right: 20px;
      }
    }
    button.Mui-selected {
      border-color: #ee7623 !important;
      background: #ee7623 !important;
      color: #fff !important;
    }

    .quantity-select {
      width: 70px;
      height: 30px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      label {
        line-height: 16px;
      }
      .MuiInputBase-root {
        border-radius: 0px;
        height: 30px;
        input {
          height: 12px !important;
          color: #999;
          font-size: 16px;
        }
      }
      fieldset.MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    .set-quantity {
      display: flex;
      border: 1px solid #9ba4af;
      border-radius: 38px;
      align-items: center;
      overflow: hidden;
      width: max-content;
      .MuiFormControl-root {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -20%;
          bottom: 0;
          background: #eee;
          width: 1px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          background: #eee;
          width: 1px;
        }
      }
      button.quantity-minmax {
        border-radius: 25px;
        // border-right: 1px solid #9BA4AF;
        height: max-content;
        min-width: 40px;
        color: #9ba4af;
        &:hover {
          background: none;
        }
      }
      button.quantity-sub {
        // border-right: 1px solid #eee;
        margin-right: 10px;
      }
      button.quantity-add {
        // border-left: 1px solid #eee;
      }
    }
    //   }
    // }
  }
  .modal-item {
    margin-top: 20px;
    gap: 50px;

    button.submit-btn {
      border: 1px solid;
      border-radius: 38px;
      color: #fff;
      background: #ee7623;
      text-transform: capitalize;

      font-size: 18px;
      padding: 5px 20px;
      &:disabled {
        color: #999999;
        background: none;
        border-color: #999999;
      }
    }
  }

  .price-per-month {
    color: #ee7623;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
}
.catalog-panel {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  padding: 10px;
  margin-top: 30px;
  border-radius: 10px;
  height: calc(100vh - 24rem);
  overflow-y: auto;
  .panel-light {
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: none;
    // padding: 35px;
    /* margin-top: 30px; */
    border-radius: 10px;
    overflow-y: auto;
    padding: 0px 15px;
    .accordian {
      border-radius: 10px;
      margin-bottom: 15px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 10px;
      box-shadow: 0px 6px 10px #00000017;
      border: 1px solid rgba(209, 210, 212, 1);
      &::before {
        display: none;
      }
      .accordian-item-heading {
        font: normal normal 700 20px/36px Raleway;
      }
      .accordian-details {
        padding-bottom: 61px;

        p.accordian-inner-para {
          font: normal normal normal 18px/36px Raleway;
          color: #666666;
        }
        .accordian-last-para {
          font: normal normal normal 18px/36px Raleway;
          color: #666666;
        }
        .light {
          background-color: #fdf1e9;
        }
        .gray {
          background-color: #f0f0f0;
        }
        .item-mspr {
          .item-label {
            font: normal normal 400 18px/36px Raleway;
            color: #666666;
          }
          .item-label-bold {
            font: normal normal 700 18px/36px Raleway;
            color: #666666;
          }
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .panel-light {
    padding: 0px 12px !important;
    .accordian {
      padding: 5px;
    }
    overflow-x: hidden;
    .accordian-item-heading {
      font-size: 20px !important;
      line-height: 25px !important;
    }
    .accordian-item-inner {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      width: calc(100% - 16px);
      margin-left: 0;
      .item-inner-col {
        width: 100%;
        max-width: 100%;
        padding: 0 !important;
        p.accordian-inner-para {
          font-size: 15px !important;
          line-height: 25px !important;
        }
        .item-mspr {
          width: 90%;
          height: max-content;
          // flex-direction: column;
          // height: 77px;
          padding: 15px;
          margin-bottom: 15px;
          p {
            font-size: 13px !important;
            line-height: 20px !important;
            // text-align: center;
          }
        }

        button.buy-sub {
          // width: max-content !important;
          height: 100% !important;
          line-height: 30px !important;
          font-size: 13px !important;
          width: 100% !important;
          margin-left: 3%;
          padding: 5px 10px !important;
        }
        .accordian-last-para {
          line-height: 25px !important;
          margin-top: 15px;
          margin-left: 3%;
        }
      }
    }
    .accordian-details {
      padding: 0px;
    }
  }

  .popup-head {
    h3 {
      font-size: 20px !important;
    }
    h5 {
      font-size: 14px !important;
    }
  }

  .price-per-month {
    font-size: 18px !important;
  }

  .modal-item {
    margin-top: 20px;
    gap: 50px;

    button.submit-btn {
      font-size: 18px !important;
      padding: 5px 20px !important;
    }
  }
}
@media (min-width: 768px) and (max-width: 1300px) {
  .accordian-item-inner {
    flex-direction: column !important;
    .item-inner-col {
      max-width: 100%;
    }
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  .item-mspr {
    height: max-content !important;
    flex-direction: column !important;
  }
}
@media (min-width: 320px) and (max-width: 450px) {
  .catalog-panel {
    padding: 10px 0px;
  }
}
@media (min-width: 1250px) and (max-width: 1450px) {
  button.buy-sub {
    margin-left: -15px;
  }
}

@media (min-width: 320px) and (max-width: 334px) {
  body {
    .popup-main {
      button.renew-option {
        padding: 5px 16px !important;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1440px) {
  .dahboard-layout-main {
    .catalog-panel {
      height: calc(100vh - 21rem);
    }
  }
}

.loader_wraper{
  padding-top: 30px;
  display: flex;
  justify-content: center;

}
