/** @format */

body {
  margin: 0;
  overflow-x: hidden;
  h1,
  h2,
  h3,
  h4,
  h5,
  h5,
  p {
    font-family: "Raleway" !important;
  }
}

.MuiList-root {
  max-height: 100px !important;
}

.modal-head {
  .icon {
    font-size: 80px;
  }
  .success {
    color: #03ad03;
  }
  .danger {
    color: rgb(255, 28, 28);
  }
}

.modal-body {
  h1 {
    font-size: 30px;
  }
  margin-bottom: 40px;
}
.become-customer-bg {
  background-image: url("../../../public/images/logoBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .become-customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .customerForm {
      background: #fff;
      border-radius: 32px;
      box-shadow: 0px 50px 60px #00000008;
      width: 60vw;
      padding: 40px 60px;
      margin-top: 30px;
      margin-bottom: 30px;

      .cutomer-heading {
        font: normal normal bold 36px/80px Raleway;
      }

      .MuiFormLabel-root {
        font: normal normal normal 20px/44px Raleway;
      }

      .MuiInputBase-root {
        border-radius: 10px;
        font: normal normal normal 20px/20px Raleway;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .customer-logo {
    width: 200px;
    height: 100px;
    object-fit: contain;
  }

  .become-customer-bg {
    .become-customer {
      margin-top: 20px;
      .customerForm {
        margin-top: 10px !important;
        width: 70vw;
        padding: 30px;

        .MuiFormLabel-root {
          font: normal normal 500 16px/44px Raleway;
        }

        .MuiButtonBase-root {
          font-size: 17px;
        }
      }
    }
  }
}
