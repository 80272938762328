.billing-container {
  font-family: "Raleway", sans-serif;
  padding: 2rem 1rem;

  .billing-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    color: #333;
    margin-bottom: 1.5rem;
  }

  .billing-description {
    font-size: 25px;
    line-height: 50px;
    color: #666;
    margin-bottom: 2rem;
  }

  .billing-list {
    margin-bottom: 2rem;

    li {
      font-size: 24px;
      line-height: 44px;
      color: #666;
      margin-bottom: 1rem;
    }
  }

  .billing-button {
    margin-top: 1.5rem;
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */

    button {
      font-size: 16px;
      font-weight: 500;
      padding: 10px 30px;
      text-transform: capitalize;
      background-color: #ee7623;

      &:hover {
        background-color: darken(#ee7623, 10%);
      }
    }
  }

  .billing-help {
    margin-top: 2rem;

    .billing-help-text {
      font-size: 24px;
      color: #666;
    }

    .billing-email-box {
      background: #fff7f2;
      display: inline-flex;
      border-radius: 38px;
      padding: 10px;
      align-items: center;
      margin-top: 30px;
      max-width: 100%;

      .mail-img {
        background: #ee7623;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0px 30px 0px 10px;
        flex-shrink: 0; /* Prevents shrinking */
      }

      .first-sec-email {
        font-size: 24px;
        color: #666666;
        font-family: "Raleway";
        flex: 1; /* Allow text to take up available space */

        span {
          font-weight: bold;
        }

        a {
          color: inherit;
          word-wrap: break-word;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .billing-container {
    padding: 1rem;

    .billing-title {
      font-size: 24px;
      line-height: 34px;
    }

    .billing-description,
    .billing-list li,
    .billing-help-text {
      font-size: 19px;
      line-height: 34px;
    }

    .billing-button {
      button {
        font-size: 15px;
        padding: 8px 20px;
      }
    }

    .billing-help {
      .billing-email-box {
        padding: 8px;

        .mail-img {
          height: 40px;
          width: 40px;
          margin: 0 15px 0 5px;
        }

        .first-sec-email {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .billing-container {
    padding: 0.5rem;

    .billing-title {
      font-size: 20px;
      line-height: 30px;
    }

    .billing-description,
    .billing-list li,
    .billing-help-text {
      font-size: 16px;
      line-height: 28px;
    }

    .billing-button {
      button {
        font-size: 14px;
        padding: 6px 16px;
      }
    }

    .billing-help {
      .billing-email-box {
        flex-direction: column; /* Stack the content vertically */
        align-items: flex-start; /* Align content to start */

        .mail-img {
          height: 35px;
          width: 35px;
          margin: 0 0 8px 0; /* Add margin below the image */
        }

        .first-sec-email {
          font-size: 14px;
          text-align: left; /* Align text to the left */
        }
      }
    }
  }
}
