footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  text-align: center;
  width: 100%;
  height: 50px;
  z-index: 1;
  margin-top: 1em;
  p {
    margin: 0;
  }
}