/** @format */
.order-table-container {
  th,
  td {
    padding: 12px !important;
    white-space: nowrap;
  }
  border-radius: 10px;
  border: 1px solid #d1d2d433;
  .action-btn {
    a {
      background: #0a7eca80;
      border: 1px solid #0a7eca;
      padding: 10px 20px;
      border-radius: 38px;
      color: #fff;
      &:hover {
        color: #fff;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
  .Mui-active {
    color: #fff !important;
    .MuiTableSortLabel-icon {
      color: #fff !important;
    }
  }
  span.MuiTableSortLabel-root {
    &:hover {
      color: #fff;
      .MuiTableSortLabel-icon {
        opacity: 1;
      }
    }
  }
}

.detail-box {
  margin-top: -15px;
}

@media (min-width: 1024px) and (max-width: 1450px) {
  .detail-box {
    margin-top: 0px;
  }

  .table-order {
    th,
    td {
      font-size: 14px !important;
    }
  }

  .table-order-detail {
    th,
    td {
      font-size: 17px !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .table-order {
    th,
    td,
    tr {
      font-size: 15px !important;
      line-height: 30px !important;
    }
  }
  .table-order-detail {
    th,
    td {
      font-size: 16px !important;
    }
  }

  .detail-box {
    margin-top: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1400px) {
  .table-order {
    @for $i from 1 through 2 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }
}

@media (min-width: 541px) and (max-width: 768px) {
  .table-order {
    @for $i from 1 through 3 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }

  .table-order-detail {
    @for $i from 1 through 1 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }
}

@media (min-width: 440px) and (max-width: 540px) {
  .table-order {
    @for $i from 1 through 4 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }

  .table-order-detail {
    @for $i from 1 through 2 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }
}

@media (min-width: 380px) and (max-width: 440px) {
  .table-order {
    @for $i from 1 through 5 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }

  .table-order-detail {
    @for $i from 1 through 3 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 379px) {
  .table-order {
    @for $i from 1 through 5 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }

  .table-order-detail {
    @for $i from 1 through 4 {
      .MuiTable-root
        .MuiTableHead-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}),
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root
        .MuiTableCell-root:nth-last-child(#{$i}) {
        display: none;
      }
    }
  }
}
