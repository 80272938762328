/** @format */

.start-top {
  position: relative;
  margin-top: -124px;
}

.about img {
  max-width: 100%;
}

.about p {
  font-size: 18px;
  line-height: 34px;
  font-weight: 300;
}

.contact-form form .input-row {
  width: 100%;
  margin-bottom: 20px;
}

.contact-form form .input-row input,
.contact-form form .input-row textarea,
.contact-form form .input-row .MuiInputBase-multiline {
  background-color: #ffffff;
  width: 100%;
}

.contact-form form .contact-radio-button .MuiFormControlLabel-label {
  color: #999999 !important;
}

.cta-section {
  margin: 50px 0;
}

.cta-section .cta {
  background-image: url("../../../public/images/cta.png");
  background-size: cover;
  background-position: center;
  color: #ffffff;
  padding: 0 30px;
  margin: 170px 0;
  border-radius: 10px;
}

.cta-section .cta .cta-content {
  height: 330px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cta-section .cta .cta-content p {
  font-size: 18px;
  font-weight: 300;
}

.cta-section .cta .cta-content a {
  font-size: 60px;
  font-weight: 700;
  line-height: 50px;
  color: #fff;
}

.cta-section .cta .cta-img {
  position: relative;
}

.cta-section .cta .cta-img .cta-img-1 {
  max-width: 46%;
  position: absolute;
  left: 0;
  top: 70px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cta-section .cta .cta-img .cta-img-1:hover {
  top: 40px;
}

.cta-section .cta .cta-img .cta-img-2 {
  max-width: 46%;
  position: absolute;
  right: 0;
  top: -150px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cta-section .cta .cta-img .cta-img-2:hover {
  top: -100px;
}

.policy-section {
  padding-top: 100px;
  margin-bottom: 40px;
}

.policy-section .policy-section-container {
  border: 1px solid #9ba4af;
  background-color: #ffffff;
  max-width: 100%;
  position: fixed;
  z-index: 3;
  bottom: 0;
  border-radius: 0px;
  padding: 20px 15px;
}

.policy-section .policy-section-container p {
  color: #666666;
  line-height: 24px;
  font-size: 18px;
  font-weight: 300;
}

.policy-section .policy-section-container .policy-btn {
  margin-top: -30px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .about .home-about-sec {
    margin-top: -30px;
  }
  .about .home-about-sec .primary-heading {
    font-size: 40px;
    margin-bottom: 0px;
  }
  .about .home-about-sec p {
    font-size: 16px;
    line-height: 28px;
  }
  .contact-form .primary-heading {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .contact-form .home-contact-option {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .contact-form .home-contact-option button {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-top: 20px;
  }
  .cta-section .cta {
    margin-left: 0;
    margin-bottom: 180px;
    width: 100%;
  }
  .cta-section .cta .cta-content p {
    font-size: 16px;
  }
  .cta-section .cta .cta-content a {
    font-size: 35px;
    line-height: 40px;
  }
  .cta-section .cta .cta-img .cta-img-1 {
    top: -80px;
  }
  .cta-section .cta .cta-img .cta-img-1:hover {
    top: -40px;
  }
  .cta-section .cta .cta-img .cta-img-2 {
    top: -450px;
  }
  .cta-section .cta .cta-img .cta-img-2:hover {
    top: -400px;
  }
  .policy-section .policy-section-container p {
    font-size: 15px;
    line-height: 26px;
    text-align: center;
  }
  .policy-section .policy-section-container .policy-btn {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .about .home-about-sec {
    margin-top: -30px;
  }
  .about .home-about-sec .primary-heading {
    font-size: 40px;
    margin-bottom: 0px;
  }
  .about .home-about-sec p {
    font-size: 16px;
    line-height: 28px;
  }
  .contact-form .primary-heading {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .contact-form .home-contact-option {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .contact-form .home-contact-option button {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-top: 20px;
  }
  .cta-section .cta {
    margin-left: 0;
    margin-bottom: 260px;
    width: 100%;
  }
  .cta-section .cta .cta-content p {
    font-size: 16px;
  }
  .cta-section .cta .cta-content a {
    font-size: 35px;
    line-height: 40px;
  }
  .cta-section .cta .cta-img .cta-img-1 {
    top: -80px;
  }
  .cta-section .cta .cta-img .cta-img-1:hover {
    top: -60px;
  }
  .cta-section .cta .cta-img .cta-img-2 {
    top: -500px;
  }
  .cta-section .cta .cta-img .cta-img-2:hover {
    top: -480px;
  }
  .policy-section .policy-section-container p {
    font-size: 15px;
    line-height: 26px;
    text-align: center;
  }
  .policy-section .policy-section-container .policy-btn {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cta-section .cta {
    margin-left: 0;
    margin-bottom: 440px;
    margin-top: 250px;
    width: 100%;
  }
  .cta-section .cta .cta-content p {
    font-size: 16px;
  }
  .cta-section .cta .cta-content a {
    font-size: 40px;
    line-height: 40px;
  }
  .cta-section .cta .cta-img .cta-img-1 {
    top: -80px;
  }
  .cta-section .cta .cta-img .cta-img-1:hover {
    top: -50px;
  }
  .cta-section .cta .cta-img .cta-img-2 {
    top: -550px;
  }
  .cta-section .cta .cta-img .cta-img-2:hover {
    top: -450px;
  }
}

@media (min-width: 1024px) and (max-width: 1250px) {
  .cta-section .cta {
    margin-bottom: 150px;
    width: 100%;
  }
  .cta-section .cta .cta-img .cta-img-1 {
    top: 100px;
  }
}
